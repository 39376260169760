import React, { useState, useEffect } from 'react'

const images = [
  'img/temp.png',
  'img/temp.png',
  'img/temp.png',
  'img/temp.png',
  'img/temp.png',
  // Add more image paths as needed
]

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [fade, setFade] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true) // Start fade out
      setTimeout(() => {
        setCurrentImage((prevIndex) => (prevIndex + 1) % images.length)
        setFade(false) // Start fade in
      }, 500) // Duration of fade out
    }, 3000) // Change image every 3 seconds

    return () => clearInterval(interval) // Cleanup on unmount
  }, [])

  return (
    <section className="flex items-center justify-center h-[300px] md:h-[700px] bg-white-200 overflow-hidden">
      <div className="relative w-full h-full">
        <img
          src={images[currentImage]}
          alt="Slideshow"
          className={`object-cover w-full h-full transition-opacity duration-500 ${
            fade ? 'opacity-0' : 'opacity-100'
          }`}
        />
      </div>
    </section>
  )
}

export default Hero
